<template>
  <div class="contactUs">
    <div class="content_area">
      <div class="title"><span>//</span>联系我们</div>
      <div class="main">
        <div class="left">
          <p>协会地址：上海市杨树浦路18号2207室</p>
          <p>邮箱：service@scba.com.cn</p>
          <p>会员管理/会费缴纳：021-68892891</p>
          <p>业务咨询/投诉热线：021-68892892</p>
          <p>行业培训/企业宣传：021-68892894</p>
          <p>党建联建/活动赞助：021-68892893</p>
        </div>
        <div class="right">
          <p>分会联系方式</p>
          <p>跨境贸易分会联系：021-20953528</p>
          <p>空运分会联系：021-58102609</p>
          <p>监事监督：021-68892899</p>
        </div>
      </div>
      <div class="add_form_box">
        <div class="adress">
          <mapPage :longitude="longitude" :dimension="dimension"></mapPage>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validateTelephone, validateEmail } from "~/baseUtils/validate";
import mapPage from "~/baseComponents/map";
export default {
  name: "association",
  components: { mapPage },
  metaInfo: {
    title: "联系我们",
  },
  data() {
    return {
      evaluateVal: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
      longitude: 121.517503,
      dimension: 31.25576,
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.contactUs {
  background: #ffffff;
  .content_area {
    padding: 30px 0 50px;
    .title {
      font-size: 24px;
      span {
        color: #e3954f;
        margin-right: 10px;
      }
    }
    .main {
      margin: 24px 0;
      p {
        text-indent: 2em;
        line-height: 25px;
      }
    }
    .add_form_box {
      display: flex;
    }
    .adress {
      height: 440px;
      width: 100%;
    }
    .formbox {
      background: #4a5a81;
      width: 502px;
      padding: 36px 32px 28px 32px;
      .ticps {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
      .name_tel {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
      }
      .email {
        margin: 16px 0;
      }
      .btn {
        margin-top: 16px;
        text-align: center;
        button {
          width: 115px;
          height: 30px;
        }
      }
    }
  }

  /deep/.el-textarea__inner {
    min-height: 154px !important;
  }
  /deep/.el-form-item__error {
    color: #d8563a;
    margin-left: 16px;
  }
}
.main {
  display: flex;
  .left {
    margin-right: 80px;
  }
}
</style>